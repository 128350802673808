import { inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

export interface AppUser {
    uid: string;
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    isOnboarded: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private http = inject(HttpClient);
    private _user: AppUser = {} as AppUser;
    private _user$ = new ReplaySubject<AppUser>(1);
    user$ = this._user$.asObservable();

    get user() {
        return this._user;
    }


    update(data: any, id: number): Observable<any> {
        return this.http.patch(`${environment.api}user/${id}`, data);
    }

    setUser(data: any) {
        this._user = {...this._user, ...data};
        this._user$.next(this._user);
    }
}

import { inject, Injectable } from '@angular/core';
import { catchError, filter, from, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import {
    Auth,
    AuthCredential,
    authState,
    createUserWithEmailAndPassword,
    EmailAuthProvider,
    getAuth,
    GoogleAuthProvider,
    Persistence, reauthenticateWithCredential, reauthenticateWithPopup,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    User,
    UserCredential
} from '@angular/fire/auth';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../storage/storage.service';
import { browserLocalPersistence } from 'firebase/auth';
import { UserService } from '../services/user/user.service';
import { pluck } from '../operators/pluck';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private auth = inject(Auth);
    private http = inject(HttpClient);
    private userService = inject(UserService);
    private storageService = inject(StorageService);
    authState$ = authState(this.auth);

    constructor() {}

    isLoggedIn(): Observable<boolean> {
        return this.authState$.pipe(
            map(user => !!user)
        )
    }

    sendEmailVerification(user: User) {
        return from(sendEmailVerification(user))
    }

    createAccountEmail(data: any): Observable<any> {
        return from(createUserWithEmailAndPassword(this.auth, data.email, data.password)).pipe(
            switchMap((res: UserCredential) => {
                const api = environment.api + 'auth/create-account';
                const body = {
                    uid: res.user.uid,
                    email: data.email,
                    firstName: data.firstName,
                    lastName: data.lastName
                };
                return this.http.post(api, body).pipe(
                    switchMap(() => this.sendEmailVerification(res.user).pipe(map(() => res.user)))
                );
            }),
            catchError(error => {
                console.log(error);
                return throwError(() => error);
            })
        );
    }

    createAccountSocial(auth: Auth, provider: GoogleAuthProvider): Observable<any> {
        return from(signInWithPopup(auth, provider)).pipe(
            switchMap((res: UserCredential) => {
                const body = {
                    uid: res.user.uid,
                    email: res.user.email
                };
                return this.createUserAccount(body);
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }

    loginWithEmail(data: { email: string, password: string }): Observable<any> {
        return from(signInWithEmailAndPassword(this.auth, data.email, data.password)).pipe(
            switchMap((res: UserCredential) => this.getUserByUid(res.user.uid)),
            tap(user => this.userService.setUser(user))
            // switchMap((userCred: UserCredential) => from(userCred.user.getIdToken())),
            // tap(token => this.storageService.add('accessToken', token))
        );
    }

    socialReauth(user: User, provider: GoogleAuthProvider): Observable<any> {
        return from(reauthenticateWithPopup(user, provider));
    };

    passwordReauth(password: string): Observable<any> {
        return this.authState$.pipe(
            filter(Boolean),
            switchMap((user: User) => {
                const credential = EmailAuthProvider.credential(user.email!, password);
                return from(reauthenticateWithCredential(user, credential))
            })
        );
    };

    socialLogin(provider: GoogleAuthProvider): Observable<any> {
        return from(signInWithPopup(this.auth, provider)).pipe(
            switchMap((res: UserCredential) => {
                return this.getUserByUid(res.user.uid).pipe(
                    catchError(error => {
                        if (error.status === 404) {
                            const body = {
                                uid: res.user.uid,
                                email: res.user.email
                            };
                            return this.createUserAccount(body);
                        }
                        return throwError(() => error);
                    })
                )
            }),
            tap(user => this.userService.setUser(user))
        );
    }

    getToken(): string {
        return this.storageService.get('accessToken');
    }

    sendVerificationEmail(user: User): Promise<any> {
        return sendEmailVerification(user);
    }

    async refreshToken(): Promise<string> {
        this.storageService.remove('accessToken');
        const token = await this.auth.currentUser?.getIdToken(true);
        if (token) {
            this.storageService.add('accessToken', token);
        }
        return token || '';
    }

    async logout(): Promise<any> {
        await signOut(this.auth);
    }

    setTokenPersistence(persistence: Persistence): Observable<void> {
        if (persistence === browserLocalPersistence) {
            this.storageService.setCookie('rememberMe', true, 365);
        } else {
            this.storageService.deleteCookie('rememberMe');
        }
        return from(this.auth.setPersistence(persistence));
    }

    getUserByUid(uid: string): Observable<any> {
        const api = environment.api + 'user/' + uid;
        return this.http.get(api).pipe(
            tap(user => this.userService.setUser(user))
        );
    }

    resetPassword(email: string) {
        const auth = getAuth();
        return from(sendPasswordResetEmail(auth, email));
    }

    deleteUser(): Observable<any> {
        return this.authState$.pipe(
            switchMap(user => {
                const api = environment.api + 'user/' + user?.uid;
                return this.http.delete(api);
            })
        );
    }

    private createUserAccount(data: any): Observable<any> {
        const api = environment.api + 'auth/create-account';
        return this.http.post(api, data);
    }

}

import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { of, switchMap } from 'rxjs';
import { AppUser } from '../services/user/user.service';

export const userResolver: ResolveFn<AppUser> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const authService = inject(AuthService);
    return authService.authState$.pipe(
        switchMap(user => {
            if (!user) {
                return of(false);
            } else {
                return authService.getUserByUid(user.uid);
            }
        })
    );
};

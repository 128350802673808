import { inject, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { StorageService } from '../../storage/storage.service';

export enum THEMES {
    DarkTeal = 'lara-dark-teal',
    LightTeal = 'lara-light-teal'
}

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    private storageService = inject(StorageService);

    constructor(@Inject(DOCUMENT) private document: Document) {
    }

    switchTheme(theme: THEMES) {
        const themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;
        const body = this.document.getElementsByTagName('body')[0] as HTMLBodyElement;

        if (themeLink) {
            this.storageService.setCookie('theme', theme, 365);
            themeLink.href = theme + '.css';
            if (theme.includes('light')) {
                body.classList.remove('dark');
                body.classList.add('light');
            } else {
                body.classList.remove('light');
                body.classList.add('dark');
            }
        }
    }

    currentTheme(): string {
        return this.storageService.getCookie('theme');
    }
}

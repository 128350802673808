import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IncExpDialogComponent } from './dialogs/inc-exp-dialog/inc-exp-dialog.component';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TooltipModule } from 'primeng/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StorageService } from './shared/storage/storage.service';
import { THEMES, ThemeService } from './shared/services/theme/theme.service';
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';

function initializeTheme(storageService: StorageService, themeService: ThemeService) {
    return (): Promise<void> => {
        return new Promise((resolve, reject) => {
            const theme = storageService.getCookie('theme') as THEMES || THEMES.DarkTeal;
            themeService.switchTheme(theme);
            resolve();
        });
    }
}

@NgModule({
    declarations: [
        AppComponent,
        IncExpDialogComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        DialogModule,
        InputTextModule,
        ReactiveFormsModule,
        ColorPickerModule,
        DropdownModule,
        RadioButtonModule,
        ButtonModule,
        InputNumberModule,
        ConfirmDialogModule,
        CalendarModule,
        HttpClientModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => getAuth()),
        provideAnalytics(() => getAnalytics()),
        InputTextareaModule,
        TooltipModule,
        FontAwesomeModule
    ],
    providers: [
        ConfirmationService,
        ScreenTrackingService,
        UserTrackingService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeTheme,
            multi: true,
            deps: [StorageService, ThemeService]
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

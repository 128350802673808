import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { catchError, from, Observable, switchMap, take, throwError } from 'rxjs';

export class TokenInterceptor implements HttpInterceptor {
    private authService = inject(AuthService);

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.authService.authState$.pipe(
            take(1),
            switchMap(user => {
                if (!user) {
                    return this.authService.logout();
                } else {
                    return from(user?.getIdToken(true) || '').pipe(
                        switchMap((accessToken: string) => {
                            return next.handle(this.addAuthenticationToken(req, accessToken));
                        })
                    )
                }
            }),
            catchError(error => {
                console.log(error);
                if (error.status === 401) {
                    return this.authService.logout();
                }
                return throwError(() => error);
            })
        );

        // return next.handle(this.addAuthenticationToken(req)).pipe(
        //     catchError(error => {
        //         if (req.url.includes('login')) {
        //             return throwError(() => error);
        //         }
        //
        //         if (error.status !==  401 && error.status !==  403) {
        //             return throwError(() => error);
        //         }
        //
        //         if (this.refreshTokenInProgress) {
        //             return this.refreshTokenSubject.pipe(
        //                 filter(res => res !== null),
        //                 first(),
        //                 switchMap(token => next.handle(this.addAuthenticationToken(req, token.accessToken)))
        //             );
        //         } else {
        //             this.refreshTokenInProgress = true;
        //             this.refreshTokenSubject.next(null);
        //             return from(this.authService.refreshToken()).pipe(
        //                 mergeMap(token =>
        //                     iif(
        //                         () => !token,
        //                         this.authService.logout(),
        //                         next.handle(this.addAuthenticationToken(req, token)).pipe(
        //                             tap(() => {
        //                                 this.refreshTokenInProgress = false;
        //                                 this.refreshTokenSubject.next(token);
        //                             })
        //                         )
        //                     )
        //                 ),
        //                 catchError(err => {
        //                     this.refreshTokenInProgress = false;
        //                     return throwError(() => err);
        //                 })
        //             );
        //         }
        //     })
        // );
    }

    private addAuthenticationToken(request: HttpRequest<any>, accessToken: string): HttpRequest<any> {

        let newHeaders = request.headers.append('Authorization', `Bearer ${accessToken}`);

        if (request.headers.get('Content-Type') === null) {
            newHeaders = newHeaders.append('Content-Type', 'application/json');
        }

        if (request.headers.get('Content-Type') === 'multipart/form-data') {
            newHeaders = newHeaders.delete('Content-Type');
        }

        return request.clone({headers: newHeaders});
    }
}

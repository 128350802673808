<form [formGroup]="dialogForm">
    <div class="formgrid grid">
        <div class="field md:col-3 col-12">
            <label for="type">Type</label>
            <p-dropdown [options]="types" inputId="type" styleClass="w-full" optionLabel="name" formControlName="type"></p-dropdown>
        </div>
        <div class="field md:col-5 col-12">
            <label for="name">Name</label>
            <input pInputText id="name" formControlName="name" class="w-full">
        </div>
        <div class="field md:col-4 col-12">
            <label for="amount">Amount</label>
            <div>
                <p-inputNumber inputId="amount" class="w-full" styleClass="w-full" formControlName="amount" mode="currency" currency="USD" [maxFractionDigits]="2" [minFractionDigits]="2"></p-inputNumber>
            </div>
        </div>
    </div>

    <div class="flex align-items-center gap-4 mb-4">
        <h4 class="m-0">Frequency</h4>
        @if (isEditing) {
            <span class="text-xs text-500 align-self-end" [pTooltip]="cantEditTooltip">Why can't I edit this?</span>

            <ng-template #cantEditTooltip>
                <span>
                    Editing the frequency is currently being developed.
                </span>

            </ng-template>
        }
    </div>

    <div class="pb-3">
        <p-radioButton [value]="false" inputId="freqOneTime" [formControl]="frequencyControl"></p-radioButton>
        <label class="pl-2" for="freqOneTime">One time</label>
    </div>

    <div class="pb-4">
        <p-radioButton [value]="true" inputId="freqRepeats" [formControl]="frequencyControl"></p-radioButton>
        <label class="pl-2" for="freqRepeats">Repeats</label>
    </div>

    @if (frequencyControl.value) {
        <ng-container formGroupName="frequency">
            <div class="formgrid grid">
                <div class="field col flex flex-row gap-2 align-items-center">
                    <p-inputNumber [size]="1" [min]="1" [max]="1000" formControlName="value" [showButtons]="true"></p-inputNumber>
                    <p-dropdown [options]="frequencyOptions" appendTo="body" optionLabel="units" formControlName="unit" (onChange)="freqChange($event)">
                        <ng-template pTemplate="selectedItem" let-unit>
                            {{dialogForm.get('frequency')?.get('unit')?.value.units[selectedFreqUnit]}}
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            {{item.units[selectedFreqUnit]}}
                        </ng-template>
                    </p-dropdown>

                    @if (dialogForm.get('frequency')?.get('unit')?.value.value === 1) {
                        <div class="flex flex-row gap-2">
                            @for (weekday of weekdays; track $index) {
                                <div (click)="toggleWeekday(weekday)"
                                     [ngClass]="{
                                 'selected': dialogForm.get('frequency')?.get('weekdays')?.value.includes(weekday),
                                 'editing-inex text-300': isEditing,
                                 'text-600 cursor-pointer': !isEditing
                                 }"
                                     class="border-circle surface-200 flex flex-row justify-content-center align-items-center text-xs font-medium weekday">
                                    {{weekday.name}}
                                </div>
                            }
                        </div>
                    }

                    @if (dialogForm.get('frequency')?.get('unit')?.value.value === 2) {
                        <div>
                            <p-dropdown [options]="monthOptions" optionLabel="name" appendTo="body" formControlName="monthOption"></p-dropdown>
                        </div>
                    }
                </div>
            </div>
        </ng-container>

        <h4>Ends</h4>
        <div class="pb-2">
            <p-radioButton [value]="false" inputId="endDateNever" formControlName="ends" (onClick)="endingChanged($event)"></p-radioButton>
            <label class="pl-2" for="endDateNever">Never</label>
        </div>
        <div class="formgrid grid align-items-center">
            <div class="col-2">
                <div>
                    <p-radioButton [value]="true" inputId="endDateOn" formControlName="ends"></p-radioButton>
                    <label class="pl-2" for="endDateOn">On</label>
                </div>
            </div>
            <div>
                <p-calendar formControlName="endDate" appendTo="body" [minDate]="data.date"></p-calendar>
            </div>
        </div>
    }

    <h4>Color <span class="text-xs text-500 font-normal">Used to represent the income or expense</span></h4>
    <label for="selectedColor">Selected color</label>
    <div class="my-2">
        <p-colorPicker format="hex" inputId="selectedColor" formControlName="color" appendTo="body"></p-colorPicker>
    </div>
    <div class="flex flex-row gap-2">
        @for (color of colorOptions; track $index) {
            <div (click)="selectColor(color)" [style.background-color]="color" class="color-option cursor-pointer"></div>
        }
    </div>

    <h4>Notes</h4>
    <textarea rows="2" cols="30" pInputTextarea formControlName="note" style="resize: none"></textarea>

    <div class="flex justify-content-end gap-1">
        <p-confirmDialog></p-confirmDialog>
        <p-button [label]="saveOrUpdateButtonText" (click)="save()" [disabled]="dialogForm.invalid"></p-button>
        @if (isEditing) {
            <p-button label="Delete" (click)="delete($event)" severity="danger"></p-button>
        }
    </div>
</form>

import { Component, inject, OnInit } from '@angular/core';
import { AuthService } from './shared/auth/auth.service';
import { take } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.sass']
})
export class AppComponent {
    private authService = inject(AuthService);
    private router = inject(Router);

    ngOnInit() {
        this.authService.authState$.subscribe(user => {
            if (!user) {
                this.router.navigateByUrl('login')
            }
        });
    }
}

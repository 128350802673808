/**
 * Days of the week represented as their corresponding indexes in JS starting with Sunday - 0 and ending on Saturday - 6
 */
export type WeekdayIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export enum INEX_EDIT_TYPE {
    SERIES,
    OCCURRENCE
}

/**
 * Whether it is an income or an expense.
 * @readonly
 * @enum {number}
 * @property {0} INCOME
 * @property {1} EXPENSE
 **/
export enum INEX_TYPE {
    INCOME,
    EXPENSE
}

/**
 * How often the income or expense is occurring.
 * @readonly
 * @enum {number}
 * @property {0} DAY
 * @property {1} WEEK
 * @property {2} MONTH
 * @property {3} YEAR
 **/
export enum INEX_FREQUENCY_TYPE {
    DAY,
    WEEK,
    MONTH,
    YEAR
}

/**
 * For monthly incomes and expenses, says whether it falls on that same day every month or the <i>nth</i> weekday of the month. ONLY FOR <b>MONTHLY</b> INEXES
 * @readonly
 * @enum {number}
 * @property {0} ON_DAY The inex falls the same day every month, i.e. the 1st of every month
 * @property {1} ORDINAL The inex falls on the same weekday every month, i.e. the first Monday of every month
 */
export enum MONTH_ORDINAL {
    ON_DAY,
    ORDINAL
}


/**
 * Enumeration representing various frequency units in text format.
 * @readonly
 * @enum {string}
 */
export enum FrequencyUnitText {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year'
}

/**
 * Enumeration for plural frequency unit text.
 * @readonly
 * @enum {string}
 */
export enum FrequencyUnitTextPlural {
    DAYS = 'days',
    WEEKS = 'weeks',
    MONTHS = 'months',
    YEARS = 'years'
}

export const FREQUENCIES = [
    {
        units: [FrequencyUnitText.DAY, FrequencyUnitTextPlural.DAYS],
        value: INEX_FREQUENCY_TYPE.DAY
    },
    {
        units: [FrequencyUnitText.WEEK, FrequencyUnitTextPlural.WEEKS],
        value: INEX_FREQUENCY_TYPE.WEEK
    },
    {
        units: [FrequencyUnitText.MONTH, FrequencyUnitTextPlural.MONTHS],
        value: INEX_FREQUENCY_TYPE.MONTH
    },
    {
        units: [FrequencyUnitText.YEAR, FrequencyUnitTextPlural.YEARS],
        value: INEX_FREQUENCY_TYPE.YEAR
    }
];

/**
 * The core income or expense.
 * @interface
 * @property {number} id ID from the database
 * @property {string} name Name of the income or expense
 * @property {number} amount The amount of the income or expense
 * @property {Date} startDate The date the income or expense starts
 * @property {Date | null} [endDate] The date the income or expense starts
 * @property {string} color The hex color of the income or expense as it's represented throughout the app
 * @property {string} [note] Any notes saved on the inex
 * @property {INEX_TYPE} type
 * @property {InexFrequency} frequency
 * @property {Date} createdAt
 * @property {Date} updatedAt
 **/
export interface Inex {
    id?: number;
    name: string;
    amount: number;
    startDate: Date;
    endDate?: Date | null;
    color: string;
    note?: string;
    type: INEX_TYPE;
    frequency: InexFrequency;
    createdAt?: Date;
    updatedAt?: Date;
    overrides: any[];
    overrideId?: number;
    overridden: boolean;
    seriesData?: any;
}

export interface SaveInexDTO {
    id?: number;
    name: string;
    amount: number;
    startDate: Date;
    endDate?: Date | null;
    color: string;
    note?: string;
    inexId: number;
    frequency: InexFrequency;
    type: INEX_TYPE;
}

export interface SaveInexOverrideDTO {
    id?: number;
    name: string;
    amount: number;
    date: Date;
    color: string;
    note?: string;
    inexId: number;
}

/**
 * The frequency of the inex.
 * @interface
 * @property {number} id ID from the database
 * @property {INEX_FREQUENCY_TYPE} frequencyType
 * @property {number} value The actual frequency, i.e. how often the inex should repeat
 * @property {number} [monthDay] The day of the month the inex falls on - ONLY FOR MONTHLY INEXES
 * @property {number} [monthWeekNumber] The week number the inex falls on every month - ONLY FOR <b>MONTHLY</b> and <b>ORDINAL</b> inexes
 * @property {MONTH_ORDINAL} [monthOrdinal] ONLY FOR <b>MONTHLY</b> INEXES
 * @property {DaysOfWeek} [daysOfWeek] ONLY FOR <b>WEEKLY</b> INEXES
 * @property {Date} createdAt
 * @property {Date} updatedAt
 */
export interface InexFrequency {
    id?: number;
    frequencyType: INEX_FREQUENCY_TYPE;
    value: number;
    monthDay?: number;
    monthWeekNumber?: number;
    monthOrdinal?: MONTH_ORDINAL;
    daysOfWeek?: DaysOfWeek[];
    createdAt?: Date;
    updatedAt?: Date;
}

/**
 * Holds what day of the week the inex happens
 * @interface
 * @property {number} id ID from the database
 * @property {WeekdayIndex} dayOfWeek
 */
export interface DaysOfWeek {
    id: number;
    dayOfWeek: WeekdayIndex;
}

export interface BreakdownExpense {
    inex: Inex,
    amountTakenFromIncome: number;
    date: Date;
}

import { inject, Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { StorageService } from '../storage/storage.service';
import { BehaviorSubject, map, Observable, of, shareReplay, Subject, switchMap, take, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Inex, InexFrequency, MONTH_ORDINAL } from './models/calendar.model';

@Injectable({
    providedIn: 'root'
})
export class CalendarService {
    private authService = inject(AuthService);
    private storageService = inject(StorageService);
    private http = inject(HttpClient);
    private dataSaved = new Subject<boolean>();
    private data = new BehaviorSubject<any[]>([]);
    dataSaved$ = this.dataSaved.asObservable();
    data$ = this.data.asObservable().pipe(shareReplay(1));

    create(data: any): Observable<any> {
        return this.authService.authState$.pipe(
            take(1),
            switchMap(user => {
                if (!user) {
                    return this.loggedOutCreate(data);
                } else {
                    return this.loggedInCreate(data);
                }
            }),
            tap(() => this.triggerDataFlow())
        );
    }

    updateSeries(id: number, data: Partial<Inex>): Observable<any> {
        return this.authService.authState$.pipe(
            take(1),
            switchMap(user => {
                if (!user) {
                    return this.loggedOutUpdate(id);
                } else {
                    return this.loggedInUpdateSeries(id, data);
                }
            }),
            tap(() => this.triggerDataFlow())
        );
    }

    updateOccurrence(data: Partial<Inex>, id?: number): Observable<any> {
        return this.authService.authState$.pipe(
            take(1),
            switchMap(user => {
                if (!user) {
                    return this.loggedOutUpdate(id);
                } else {
                    return this.loggedInUpdateOccurrence(data, id);
                }
            }),
            tap(() => this.triggerDataFlow())
        );
    }

    delete(id: number): Observable<any> {
        return this.authService.authState$.pipe(
            take(1),
            switchMap(user => {
                if (!user) {
                    return this.loggedOutDelete(id);
                } else {
                    return this.loggedInDelete(id);
                }
            }),
            tap(() => this.triggerDataFlow())
        );
    }

    deleteOccurrence(id: number): Observable<any> {
        return this.authService.authState$.pipe(
            take(1),
            switchMap(user => {
                if (!user) {
                    return this.loggedOutDelete(id);
                } else {
                    return this.loggedInDeleteOccurrence(id);
                }
            }),
            tap(() => this.triggerDataFlow())
        );
    }

    notLoggedInSave(data: any): void {
        this.storageService.add('inex', JSON.stringify(data));
    }

    getData(): Observable<Inex[]> {
        return this.authService.authState$.pipe(
            switchMap(user => {
                if (user) {
                    return this.getLoggedInData();
                } else {
                    return of(JSON.parse(this.storageService.get('inex')) || []);
                }
            })
        );
    }

    triggerDataFlow(): void {
        this.getData().pipe(take(1)).subscribe(res => this.data.next(res));
    }

    publishDataSaved(dataSaved: boolean): void {
        this.dataSaved.next(dataSaved);
    }

    nextMonthStartDate(d: Date): Date {
        const nextMonth = new Date(new Date(d).setMonth(d.getMonth() + 1));
        return new Date(nextMonth.getFullYear(), nextMonth.getMonth(), 1);
    }

    daysInMonth(month: number, year: number) {
        return new Date(year, month, 0).getDate();
    }

    daysAreEqual(currentDate: Date, compareDate: Date): boolean {
        return currentDate.getDate() === compareDate.getDate() &&
            currentDate.getMonth() === compareDate.getMonth() &&
            currentDate.getFullYear() === compareDate.getFullYear();
    }

    isToday(d: Date) {
        const today = new Date();
        return this.daysAreEqual(d, today);
    }

    getSunday() {
        const d = new Date();
        const first = d.getDate() - d.getDay();
        return new Date(d.setDate(first));
    }

    firstDayOfMonth(date: Date): Date {
        return new Date(date.getFullYear(), date.getMonth(), 1);
    }

    lastDayOfMonth(date: Date): Date {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    isBetweenDatesInclusive(currentDate: Date, earlierDate: Date, laterDate: Date): boolean {
        return this.isBeforeDayInclusive(currentDate, laterDate) && this.isAfterDayInclusive(currentDate, earlierDate);
    }

    isBeforeDayInclusive(currentDate: Date, compareDate: Date): boolean {
        return currentDate.getFullYear() < compareDate.getFullYear() ||
            (currentDate.getFullYear() === compareDate.getFullYear() &&
                currentDate.getMonth() < compareDate.getMonth()) ||
            (currentDate.getFullYear() === compareDate.getFullYear()
                && currentDate.getMonth() === compareDate.getMonth() &&
                currentDate.getDate() <= compareDate.getDate());
    }

    isBeforeMonthInclusive(currentDate: Date, compareDate: Date): boolean {
        return currentDate.getFullYear() < compareDate.getFullYear() ||
            (currentDate.getFullYear() === compareDate.getFullYear() &&
                currentDate.getMonth() < compareDate.getMonth());
    }

    isBeforeDayExclusive(currentDate: Date, compareDate: Date): boolean {
        return currentDate.getFullYear() < compareDate.getFullYear() ||
            (currentDate.getFullYear() === compareDate.getFullYear() &&
                currentDate.getMonth() < compareDate.getMonth()) ||
            (currentDate.getFullYear() === compareDate.getFullYear()
                && currentDate.getMonth() === compareDate.getMonth() &&
                currentDate.getDate() < compareDate.getDate());
    }

    isAfterDayInclusive(currentDate: Date, compareDate: Date): boolean {
        return currentDate.getFullYear() > compareDate.getFullYear() ||
            (currentDate.getFullYear() === compareDate.getFullYear() &&
                currentDate.getMonth() > compareDate.getMonth()) ||
            (currentDate.getFullYear() === compareDate.getFullYear()
                && currentDate.getMonth() === compareDate.getMonth() &&
                currentDate.getDate() >= compareDate.getDate());
    }

    isAfterDayExclusive(currentDate: Date, compareDate: Date): boolean {
        return currentDate.getFullYear() > compareDate.getFullYear() ||
            (currentDate.getFullYear() === compareDate.getFullYear() &&
                currentDate.getMonth() > compareDate.getMonth()) ||
            (currentDate.getFullYear() === compareDate.getFullYear()
                && currentDate.getMonth() === compareDate.getMonth() &&
                currentDate.getDate() > compareDate.getDate());
    }

    isInCurrentMonth(currentDate: Date, compareDate: Date): boolean {
        return currentDate.getFullYear() === compareDate.getFullYear() && currentDate.getMonth() === compareDate.getMonth();
    }

    /**
     * Calculates the next occurrence of the inex based on frequency type
     * @param {InexFrequency} frequency
     * @param {Date} currentDate
     */
    calculateNextOccurrence(frequency: InexFrequency, currentDate: Date): Date {
        let nextDate = new Date(currentDate);
        switch (frequency.frequencyType) {
            case 0:
            default:
                nextDate.setDate(currentDate.getDate() + frequency.value);
                break;
            case 1:
                nextDate.setDate(currentDate.getDate() + (frequency.value * 7));
                break;
            case 2:
                if (frequency.monthOrdinal === MONTH_ORDINAL.ON_DAY) {
                    nextDate.setMonth(currentDate.getMonth() + frequency.value);
                } else {
                    nextDate = this.nextMonthStartDate(currentDate);
                    nextDate.setDate(1 + (7 - currentDate.getDay() + frequency.monthDay!) % 7 + (frequency.monthWeekNumber! - 1) * 7);
                }
                break;
            case 3:
                nextDate.setFullYear(currentDate.getFullYear() + frequency.value);
                break;
        }
        return nextDate;
    }

    calculatePreviousOccurrence(frequency: InexFrequency, currentDate: Date): Date {
        let previousDate = new Date(currentDate);
        switch (frequency.frequencyType) {
            case 0:
            default:
                previousDate.setDate(currentDate.getDate() - frequency.value);
                break;
            case 1:
                previousDate.setDate(currentDate.getDate() - (frequency.value * 7));
                break;
            case 2:
                if (frequency.monthOrdinal === MONTH_ORDINAL.ON_DAY) {
                    previousDate.setMonth(currentDate.getMonth() - frequency.value);
                } else {
                    previousDate = this.nextMonthStartDate(currentDate);
                    previousDate.setDate(1 + (7 - currentDate.getDay() + frequency.monthDay!) % 7 - (frequency.monthWeekNumber! - 1) * 7);
                }
                break;
            case 3:
                previousDate.setFullYear(currentDate.getFullYear() - frequency.value);
                break;
        }
        return previousDate;
    }

    totalBetweenDates(inex: Inex[] = [], startDate: Date, endDate?: Date): number {
        let total = 0;
        inex.forEach((inex: Inex) => {
            let currentDate = new Date(inex.startDate);
            let inexEndDate;
            if (inex.endDate) {
                inexEndDate = new Date(inex.endDate);
            }

            while (endDate && this.isBeforeDayInclusive(currentDate, endDate)  && (!inexEndDate || this.isBeforeDayInclusive(currentDate, inexEndDate))) {
                if (this.isBetweenDatesInclusive(currentDate, startDate, endDate)) {
                    const override = inex.overrides.find(override => {
                        return this.daysAreEqual(new Date(override.date), currentDate);
                    }) ?? inex;
                    total += override.amount;
                }
                currentDate = this.calculateNextOccurrence(inex.frequency, currentDate);
            }
        });
        return total;
    }

    private loggedInCreate(data: any) {
        return this.http.post(`${environment.api}inex` , data);
    }

    private loggedOutCreate(data: any) {
        return of(data);
    }

    private loggedInDelete(id: number) {
        return this.http.delete(`${environment.api}inex/${id}`).pipe(tap(() => this.triggerDataFlow()));
    }

    private loggedInDeleteOccurrence(id: number) {
        return this.http.delete(`${environment.api}inex-override/${id}`).pipe(tap(() => this.triggerDataFlow()));
    }

    private loggedOutDelete(id: number) {
        return of(id);
    }

    private loggedInUpdateSeries(id: number, data: Partial<Inex>) {
        return this.http.patch(`${environment.api}inex/${id}`, data).pipe(tap(() => this.triggerDataFlow()));
    }

    private loggedInUpdateOccurrence(data: Partial<Inex>, id?: number) {
        let url = 'inex-override';
        if (id) {
            url += `/${id}`;
        }
        return this.http.put(`${environment.api}${url}`, data).pipe(tap(() => this.triggerDataFlow()));
    }

    private loggedOutUpdate(id?: number) {
        return of(id);
    }

    private getLoggedInData(): Observable<Inex[]> {
        return this.authService.authState$.pipe(
            map(user => user?.uid),
            switchMap(uid => {
                return this.http.get<Inex[]>(`${environment.api}inex`);
            }),
            map(res => {
                res.forEach((inex: Inex) => {
                    inex.startDate = new Date(inex.startDate);
                    inex.endDate = inex.endDate ? new Date(inex.endDate) : null;
                    inex.createdAt = new Date(inex.createdAt!);
                    inex.updatedAt = new Date(inex.updatedAt!);
                    inex.frequency.createdAt = new Date(inex.frequency.createdAt!);
                    inex.frequency.updatedAt = new Date(inex.frequency.updatedAt!);
                });
                return res;
            })
        );
    }
}

export const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyB-irNo0JKcMkuTAETu57Dd6xcOlDufsuo",
        authDomain: "atombudget-test.firebaseapp.com",
        projectId: "atombudget-test",
        storageBucket: "atombudget-test.appspot.com",
        messagingSenderId: "122473817073",
        appId: "1:122473817073:web:f1a35fad49f77e12eb5a50",
        measurementId: "G-QJEVZG0MSZ"
    },
    api: "https://ab-api-test.up.railway.app/"
};

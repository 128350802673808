import { Component, inject, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { CalendarService } from '../../shared/calendar/calendar.service';
import { ConfirmationService } from 'primeng/api';
import {
    FREQUENCIES,
    Inex,
    INEX_EDIT_TYPE,
    INEX_TYPE,
    InexFrequency,
    MONTH_ORDINAL,
    SaveInexDTO,
    SaveInexOverrideDTO,
    WeekdayIndex
} from '../../shared/calendar/models/calendar.model';


interface Weekday {
    name: string;
    value: WeekdayIndex;
}

interface MonthOption {
    name: string;
    dayNumber?: number;
    weekdayNumber?: number;
    weekdayIndex?: number;
    value: MONTH_ORDINAL
}

const WeekOrdinals = {
    1: 'first',
    2: 'second',
    3: 'third',
    4: 'fourth',
    5: 'last'
}

@Component({
    selector: 'app-inc-exp-dialog',
    templateUrl: './inc-exp-dialog.component.html',
    styleUrls: ['./inc-exp-dialog.component.sass']

})
export class IncExpDialogComponent implements OnInit {
    private calendarService = inject(CalendarService);
    private config = inject(DynamicDialogConfig);
    private ref = inject(DynamicDialogRef);
    private confirmationService = inject(ConfirmationService);
    data = this.config.data;
    dialogForm!: FormGroup;
    types: { name: string, value: 0 | 1 }[] = [
        {
            name: 'Income',
            value: INEX_TYPE.INCOME
        },
        {
            name: 'Expense',
            value: INEX_TYPE.EXPENSE
        }
    ];
    frequencyOptions = FREQUENCIES;
    weekdays: Weekday[] = [
        {
            name: 'S',
            value: 0
        },
        {
            name: 'M',
            value: 1
        },
        {
            name: 'T',
            value: 2
        },
        {
            name: 'W',
            value: 3
        },
        {
            name: 'T',
            value: 4
        },
        {
            name: 'F',
            value: 5
        },
        {
            name: 'S',
            value: 6
        }
    ];
    monthOptions: MonthOption[] = [];
    colorOptions: string[] = [
        '#85e0a3',
        '#80caff',
        '#ffafa3',
        '#d9b8ff',
        '#ffd966',
        '#ffade7',
        '#ffc470',
        '#afbccf'
    ];
    selectedFreqUnit = 0;
    isEditing = false;
    saveOrUpdateButtonText = 'Save';
    frequencyControl = new FormControl(true);

    ngOnInit() {
        this.initForm();
        this.dialogForm.get('frequency')?.get('value')?.valueChanges.subscribe((val: number) => {
            this.selectedFreqUnit = val === 1 ? 0 : 1;
        });
        this.dialogForm.get('ends')?.valueChanges.subscribe((val: boolean) => {
            val ? this.dialogForm.get('endDate')?.enable() : this.dialogForm.get('endDate')?.disable();
        });
        if (this.data.event?.id) {
            this.isEditing = true;
            this.saveOrUpdateButtonText = 'Update';
            this.editForm(this.data.event, this.data.event.frequency);
        }
    }

    toggleWeekday(weekday: Weekday): void {
        if (this.isEditing) {
            return;
        }
        const index = this.dialogForm.get('frequency')?.get('weekdays')?.value.indexOf(weekday)
        if (index >= 0) {
            this.dialogForm.get('frequency')?.get('weekdays')?.value.splice(index, 1);
        } else {
            this.dialogForm.get('frequency')?.get('weekdays')?.value.push(weekday);
        }
    }

    freqChange(event: DropdownChangeEvent): void {
        if (event.value.value === 1) {
            this.dialogForm.get('frequency')?.get('monthOption')?.setValue(null);
            this.dialogForm.get('frequency')?.get('weekdays')?.value.push(
                this.weekdays.find(weekday => weekday.value === this.dialogForm.get('startDate')?.value?.getDay())
            );
        }

        if (event.value.value === 2) {
            this.dialogForm.get('frequency')?.get('weekdays')?.setValue([]);
            this.setMonthOptions(this.data.date)
        }
    }

    endingChanged(event: any) {
        if (!event.value) {
            this.dialogForm.get('endDate')?.patchValue(null);
        }
    }

    selectColor(color: string): void {
        this.dialogForm.get('color')?.setValue(color);
    }

    save(): void {
        if (this.dialogForm.invalid) {
            return;
        }

        // const res = this.calendarService.getData();
        // const oldSaveData = {...this.dialogForm.getRawValue()};
        const saveData: Partial<SaveInexDTO> = {
            name: this.dialogForm.get('name')?.value,
            amount: this.dialogForm.get('amount')?.value,
            color: this.dialogForm.get('color')?.value,
            note: this.dialogForm.get('note')?.value
        };

        if (this.isEditing) {
            // const itemIndex = res.findIndex(item => item.id = this.data.event.id);
            // res.splice(itemIndex, 1);
            // oldSaveData.id = this.data.event.id;
            if (this.data.editType === INEX_EDIT_TYPE.SERIES) {
                saveData.type = this.dialogForm.get('type')?.value?.value;
                saveData.endDate = this.dialogForm.get('endDate')?.value;
                this.calendarService.updateSeries(this.dialogForm.get('id')?.value, saveData).subscribe(res => {
                    this.ref.close();
                });
            } else {
                const updateData = {...saveData} as SaveInexOverrideDTO;
                updateData.inexId = this.dialogForm.get('id')?.value;
                updateData.date = this.data.event.editDate;
                this.calendarService.updateOccurrence(updateData, this.data.event.overrideId).subscribe(res => {
                    this.ref.close();
                });
            }
        } else {
            // oldSaveData.id = crypto.randomUUID();
            saveData.startDate = this.dialogForm.get('startDate')?.value;
            saveData.type = this.dialogForm.get('type')?.value?.value;

            if (!this.frequencyControl.value) {
                saveData.endDate = this.dialogForm.get('startDate')?.value;
                saveData.frequency = {
                    value: 1,
                    frequencyType: 0,
                    daysOfWeek: []
                };
            } else {
                saveData.endDate = this.dialogForm.get('endDate')?.value;
                saveData.frequency = {
                    value: this.dialogForm.get('frequency')?.get('value')?.value,
                    frequencyType: this.dialogForm.get('frequency')?.get('unit')?.value.value,
                    monthDay: this.dialogForm.get('frequency')?.get('monthOption')?.value?.dayNumber,
                    monthOrdinal: this.dialogForm.get('frequency')?.get('monthOption')?.value?.value,
                    daysOfWeek: this.dialogForm.get('frequency')?.get('weekdays')?.value?.map((day: Weekday) => {
                        return {dayOfWeek: day.value};
                    })
                };
            }
            this.calendarService.create(saveData).subscribe(res => {
                this.ref.close();
            });
        }
        // res.push(oldSaveData);
    }

    delete(event: Event): void {
        const name = this.data.event.name;
        const message = this.data.editType === INEX_EDIT_TYPE.SERIES ?
            `Are you sure you want to delete the series ${name}?` :
            `Are you sure you want to delete the occurrence ${name}?`;
        this.confirmationService.confirm({
            target: event.target as EventTarget,
            message,
            acceptIcon: "none",
            rejectIcon: "none",
            rejectButtonStyleClass: "p-button-text",
            accept: () => {
                if (this.data.editType === INEX_EDIT_TYPE.SERIES) {
                    this.calendarService.delete(this.dialogForm.get('id')?.value).subscribe(res => {
                        this.ref.close();
                    });
                } else {
                    this.calendarService.deleteOccurrence(this.data.event.overrideId).subscribe(res => {
                        this.ref.close();
                    });
                }

            }
        });

        // const res = this.calendarService.getData();
        // const itemIndex = res.findIndex(item => item.id = this.data.event.id);
        // this.confirmationService.confirm({
        //     target: event.target as EventTarget,
        //     message: `Are you sure you want to delete ${res[itemIndex].name}?`,
        //     acceptIcon:"none",
        //     rejectIcon:"none",
        //     rejectButtonStyleClass:"p-button-text",
        //     accept: () => {
        //         res.splice(itemIndex, 1);
        //         this.calendarService.save(res);
        //         this.calendarService.publishDataSaved(true);
        //         this.ref.close();
        //     }
        // });
    }

    private initForm(): void {
        this.dialogForm = new FormGroup({
            id: new FormControl(null),
            type: new FormControl(this.types[0], Validators.required),
            name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
            amount: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(1000000000)]),
            color: new FormControl('#000000'),
            note: new FormControl(''),
            startDate: new FormControl(this.data.date),
            endDate: new FormControl({value: null, disabled: true}),
            ends: new FormControl(0),
            frequency: new FormGroup({
                value: new FormControl(1, [Validators.required, Validators.min(1), Validators.max(1000)]),
                unit: new FormControl(this.frequencyOptions[0], Validators.required),
                weekdays: new FormControl([]),
                monthOption: new FormControl(null)
            })
        });
    }

    private editForm(inex: Inex, frequency: InexFrequency): void {
        if (inex.frequency.frequencyType === 2) {
            this.setMonthOptions(inex.startDate);
        }

        if (inex.endDate && this.calendarService.daysAreEqual(inex.startDate, inex.endDate)) {
            this.frequencyControl.patchValue(false);
        }

        this.dialogForm.patchValue({
            id: inex.id,
            type: this.types.find(type => type.value === inex.type),
            name: inex.name,
            amount: inex.amount,
            color: inex.color,
            note: inex.note,
            startDate: inex.startDate,
            endDate: inex.endDate,
            ends: !!inex.endDate,
            frequency: {
                value: frequency.value,
                unit: this.frequencyOptions.find(freq => freq.value === frequency.frequencyType),
                weekdays: this.weekdays.filter(weekday => frequency.daysOfWeek?.map(day => day.dayOfWeek).includes(weekday.value)),
                monthOption: this.monthOptions.find(option => option.value === frequency.monthOrdinal)
            }
        });

        // I need to assign the objects from this.weekdays array so the form can use the reference and update itself accordingly
        const editWeekdays = this.dialogForm.get('frequency')?.get('weekdays')?.value;
        const newWeekdays: any[] = [];
        editWeekdays.forEach((weekday: Weekday) => {
            newWeekdays.push(this.weekdays.find(w => w.value === weekday.value));
        });
        this.dialogForm.get('frequency')?.get('weekdays')?.setValue(newWeekdays);
        this.dialogForm.get('frequency')?.disable();
        this.frequencyControl.disable();
    }

    private setMonthOptions(date: Date): void {
        this.monthOptions = [];
        const day = date.getDate();
        const ordinal = WeekOrdinals[Math.ceil(day / 7) as keyof typeof WeekOrdinals];
        this.monthOptions.push({
            name: `Monthly on day ${day}`,
            dayNumber: day,
            value: MONTH_ORDINAL.ON_DAY
        });
        this.monthOptions.push({
            name: `Monthly on the ${ordinal} ${date.toLocaleDateString('en-US', {weekday: 'long'})}`,
            weekdayNumber: Math.ceil(day / 7),
            weekdayIndex: date.getDay(),
            value: MONTH_ORDINAL.ORDINAL
        });
        this.dialogForm.get('frequency')?.get('monthOption')?.setValue(this.monthOptions[0]);
    }
}

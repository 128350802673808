import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor() {

    }

    add(key: string, value: any): void {
        localStorage.setItem(key, value);
    }

    get(key: string): any {
        return localStorage.getItem(key);
    }

    remove(key: string): void {
        localStorage.removeItem(key);
    }

    clear(): void {

    }

    setCookie(cookieName: string, cookieValue: any, expireDays: number) {
        const d = new Date();
        d.setTime(d.getTime() + (expireDays * 24 * 60 * 60 * 1000));
        let expires = "expires="+d.toUTCString();
        document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
    }

    getCookie(cookieName: string) {
        let name = cookieName + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    deleteCookie(cookieName: string) {
        const cookie = this.getCookie(cookieName);
        if (cookie) {
            this.setCookie(cookieName, '', -1);
        }
    }

}
